import { StoreAction } from "../../@core/constants/action";

const initialState = {
  activeChat: 0,
  allChatList: {}, // side-bar chat list
  selectedChat: {}, // Selected Chat
  chatFilter: false,
  chatSideBar: false,
  chatLoading: false,
  getChatList: false, // Track Chat list get or not
  scrollAdject: false, // Scroll adject on evry render
  stopScrolling: false,
  scrollLoading: false, // Scroll Loader show in selected chat
  isSendMessage: false, // Send Message Button disable
  userDetailSidebar: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case StoreAction.SET_ACTIVE_CHAT:
      return { ...state, activeChat: action.payload };
    case StoreAction.SET_CHAT_FILTER:
      return { ...state, chatFilter: action.payload };
    case StoreAction.SET_GET_CHAT_LIST:
      return { ...state, getChatList: action.payload };
    case StoreAction.SET_CHAT_LOADING:
      return { ...state, chatLoading: action.payload };
    case StoreAction.SET_CHAT_SIDE_BAR:
      return { ...state, chatSideBar: action.payload };
    case StoreAction.GET_ALL_CHAT_LIST:
      return { ...state, allChatList: action.payload };
    case StoreAction.SET_SCROLL_ADJECT:
      return { ...state, scrollAdject: action.payload };
    case StoreAction.SET_SELECTED_CHAT:
      return { ...state, selectedChat: action.payload };
    case StoreAction.SET_IS_SEND_MESSAGE:
      return { ...state, isSendMessage: action.payload };
    case StoreAction.SET_SCROLL_LOADING:
      return { ...state, scrollLoading: action.payload };
    case StoreAction.SET_STOP_SCROLLING:
      return { ...state, stopScrolling: action.payload };
    case StoreAction.SET_USER_DETAILS_SIDEBAR:
      return { ...state, userDetailSidebar: action.payload };
    default:
      return state;
  }
}
